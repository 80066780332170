"use client";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import ButtonCopy from "../button/ButtonCopy";
import Link from "next/link";
export default function HowToPlayUI(props: any) {
  const {
    howtoplay
  } = props;
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  return <>
            {/* <div className="fixed inset-0 flex items-center justify-center"> */}
            <button aria-label="How To Play" type="button" onClick={openModal} className="cursor-pointer font-nexabold py-4 px-4 rounded-sm bg-primary drop-shadow-lg text-3xl text-black italic hover:bg-primaryhover hover:text-white">
                PLAY NOW
            </button>
            {/* </div> */}

            <Transition appear show={isOpen} as={Fragment} data-sentry-element="Transition" data-sentry-source-file="HowToPlayUI.tsx">
                <Dialog as="div" className="relative z-10" onClose={closeModal} data-sentry-element="Dialog" data-sentry-source-file="HowToPlayUI.tsx">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="HowToPlayUI.tsx">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto rounded-lg text-white">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" data-sentry-element="unknown" data-sentry-source-file="HowToPlayUI.tsx">
                                <Dialog.Panel className="w-full max-w-screen-sm py-10 transform overflow-hidden rounded-md bg-slate-800 ring-2 ring-purple-300 p-6 text-left align-middle shadow-xl transition-all" data-sentry-element="unknown" data-sentry-source-file="HowToPlayUI.tsx">
                                    <div className="flex flex-wrap justify-between">
                                        <Dialog.Title as="h2" className="text-4xl font-medium leading-6 text-white" data-sentry-element="unknown" data-sentry-source-file="HowToPlayUI.tsx">
                                            How to play?
                                        </Dialog.Title>
                                        <button className="p-1 ml-auto bg-transparent border-0 text-black bg-yellow-200 rounded-md float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={() => setIsOpen(false)}>
                                            <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">X</span>
                                        </button>
                                    </div>
                                    <div className="py-10">
                                        <section className="mb-8">
                                            <h2 className="text-xl font-bold mb-2">Launch Minecraft:</h2>
                                            <p>Open your Minecraft launcher and log in with your Mojang account.</p>
                                        </section>
                                        <section className="mb-8">
                                            <h2 className="text-xl font-bold mb-2">Access Multiplayer:</h2>
                                            <p>Click on the &quot;Multiplayer&quot; or &quot;Play Multiplayer&quot; option in the Minecraft main menu.</p>
                                        </section>
                                        <section className="mb-8">
                                            <h2 className="text-xl font-bold mb-2">Add Server:</h2>
                                            {/* <p>Look for a button that says &quot;Add Server,&quot; &quot;Direct Connect,&quot; or something similar.</p>
                                             <p>Enter the server IP:</p> */}
                                            <div className="mt-4">
                                                <ButtonCopy text="Copy IP" ip={"play.luckynetwork.net"} data-sentry-element="ButtonCopy" data-sentry-source-file="HowToPlayUI.tsx" />
                                            </div>
                                        </section>
                                        <section className="mb-8">
                                            <h2 className="text-xl font-bold mb-2">Join the Server:</h2>
                                            <p>Once you&apos;ve added the server, select it from the server list and click &quot;Join Server&quot; or a similar option.</p>
                                        </section>
                                        <section className="mb-8">
                                            <h2 className="text-xl font-bold mb-2">Server Rules:</h2>
                                            <p>
                                                Be aware of any specific rules or requirements the server may have.{" "}
                                                <span className="text-red-200 block text-lg font-bold underline">
                                                    <Link href="/rules" data-sentry-element="Link" data-sentry-source-file="HowToPlayUI.tsx">READ THE RULES</Link>
                                                </span>
                                            </p>
                                        </section>
                                        <section>
                                            <h2 className="text-xl font-bold mb-2">Enjoy the Game:</h2>
                                            <p>Once connected, you&apos;ll enter the server&apos;s world, and you can start playing with others.</p>
                                        </section>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>;
}