"use client";

import styles from "./Button.module.css";
import React, { useState } from "react";
import Swal from "sweetalert2";
export default function ButtonCopy({
  text,
  ip
}: {
  text: string;
  ip: string;
}) {
  const copyIP = () => {
    navigator.clipboard.writeText(ip);
    Swal.fire({
      title: "Copied!",
      text: "The IP has been copied to your clipboard.",
      icon: "success",
      confirmButtonText: "Ok"
    });
  };
  return <button className="cursor-pointer font-nexabold inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-xl font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={copyIP} aria-label="Copy IP Lucky Network" data-sentry-component="ButtonCopy" data-sentry-source-file="ButtonCopy.tsx">
            {text}
        </button>;
}